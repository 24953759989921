// ImportPage.jsx
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { message, Modal } from 'antd';
import { Helmet } from 'react-helmet';
import MovingDotsBackground from './MovingDotsBackground'; // Adjust the path as necessary

const ImportPage = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [userId, setUserId] = useState(null);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [importResult, setImportResult] = useState(null);
  const [newBoardId, setNewBoardId] = useState(null);
  const [error, setError] = useState(null);
  const [importTriggered, setImportTriggered] = useState(false); // Flag to prevent multiple imports
  const navigate = useNavigate();
  const location = useLocation();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const boardId = query.get('board_id');

  // Effect to set userId from Auth0 user or localStorage
  useEffect(() => {
    if (isLoading) return; // Wait until Auth0 finishes loading

    if (isAuthenticated && user) {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId) {
        setUserId(storedUserId);
      } else {
        // Extract userId from Auth0 user object and store it
        const extractedUserId = user.sub; // Adjust based on your Auth0 setup
        localStorage.setItem('userId', extractedUserId);
        setUserId(extractedUserId);
      }
    } else {
      setUserId(null);
    }
  }, [isAuthenticated, user, isLoading]);

  // Effect to trigger import once userId and boardId are available
  useEffect(() => {
    if (isLoading) return; // Wait until Auth0 finishes loading

    if (!boardId) {
      setError('No board_id provided in the URL.');
      setLoading(false);
      return;
    }

    if (isAuthenticated && userId && !importTriggered) {
      handleImport(boardId, userId);
      setImportTriggered(true); // Ensure import is triggered only once
    } else if (!isAuthenticated) {
      setIsLoginModalVisible(true);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userId, boardId, isLoading, importTriggered]);

  const handleImport = async (boardId, userId) => {
    setLoading(true); // Start loading
    try {
      console.log('Sending import request with userId:', userId, 'and boardId:', boardId);
      const response = await axios.post('https://nodecodestudio.com/backend/import_shared_board.php', {
        user_id: userId,
        board_id: boardId,
      });
      console.log('Import response:', response.data);

      // Assuming the response is { "new_board_id": 166 }
      if (response.data.new_board_id) {
        setImportResult(`Successfully imported workflow with id: ${response.data.new_board_id}`);
        setNewBoardId(response.data.new_board_id);
        message.success(`Successfully imported workflow with id: ${response.data.new_board_id}`);
      } else {
        setError(response.data.message || 'Import failed.');
        message.error(response.data.message || 'Import failed.');
      }
    } catch (error) {
      console.error('Import Error:', error);
      setError('An error occurred during import.');
      message.error('An error occurred during import.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Import Workflow</title>
        <meta name="description" content="Import a shared workflow into your workspace." />
        {/* Add other meta tags as needed */}
      </Helmet>
      <MovingDotsBackground />
      <Container className="main-content">
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h1 className="mt-5">Import Workflow</h1>
            {loading && (
              <div className="mt-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>Importing your workflow...</p>
              </div>
            )}
            {!loading && importResult && (
              <Alert variant="success" className="mt-4 import-page-style ">
                {importResult}
                <div className="mt-3 d-flex justify-content-center gap-3 import-page-style">
                  {/* Button 1: Go to Import Page */}
                  <Button
               
                    className="import-page-btn"
                    onClick={() => window.open('https://nodecodestudio.com/workflows/', '_blank')}
                  >
                   Back to Imports
                  </Button>
                  {/* Button 2: Go to Imported Workflow */}
                  <Button
                   className="import-workflow-edit-btn"
                    onClick={() => navigate(`/node-creator?id=${newBoardId}`)}
                  >
                    Edit Workflow
                  </Button>
                </div>
              </Alert>
            )}
            {!loading && error && (
              <Alert variant="danger" className="mt-4">
                {error}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>

      {/* Modal for Login/Signup Prompt */}
      <Modal
        open={isLoginModalVisible}
        style={{ textAlign: 'center' }}
        onCancel={() => setIsLoginModalVisible(false)}
        footer={[
          <div className="get-started-buttons" key="footer">
            <Button key="cancel" variant="secondary" onClick={() => setIsLoginModalVisible(false)}>
              Cancel
            </Button>
            <Button
              key="getStarted"
              variant="primary"
              onClick={() => {
                setIsLoginModalVisible(false);
                loginWithRedirect();
              }}
            >
              Get Started
            </Button>
          </div>,
        ]}
      >
        <h2>Authentication Required</h2>
        <p>Login or sign up to import this workflow.</p>
      </Modal>
    </>
  );
};

export default ImportPage;