// src/encryption.js
import CryptoJS from "crypto-js";

// Secret key must be the same as the backend
const ENCRYPTION_KEY = "thesecurekey1234thesecurekey1234"; // 32 characters for AES-256

/**
 * Encrypts the given plaintext using AES-256-CBC.
 *
 * @param {string} plaintext The API key to encrypt.
 * @return {string} The encrypted data in the format "base64(iv)::base64(encrypted_data)".
 */
export function encryptApiKey(plaintext) {
  try {
    const iv = CryptoJS.lib.WordArray.random(16); // 16 bytes IV for AES-256-CBC
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    return `${ivBase64}::${encryptedBase64}`;
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
}

/**
 * Decrypts the given encrypted data using AES-256-CBC.
 *
 * @param {string} encryptedData The encrypted data in the format "base64(iv)::base64(encrypted_data)".
 * @return {string} The decrypted plaintext.
 */
export function decryptApiKey(encryptedData) {
  try {
    // If encryptedData is falsy, or literally "null"/"undefined", skip
    if (
      !encryptedData ||
      encryptedData.trim().toLowerCase() === "null" ||
      encryptedData.trim().toLowerCase() === "undefined"
    ) {
      console.warn("No valid data provided to decrypt, returning empty string.");
      return "";
    }

    if (typeof encryptedData !== "string") {
      throw new Error("Encrypted data must be a string.");
    }

    // Split the IV and Encrypted data
    const parts = encryptedData.split("::");
    if (parts.length !== 2) {
      throw new Error("Invalid encrypted data format.");
    }

    const [ivBase64, encryptedBase64] = parts;
    if (!ivBase64 || !encryptedBase64) {
      throw new Error("IV or encrypted data is missing.");
    }

    // Decode IV and Ciphertext from Base64
    const iv = CryptoJS.enc.Base64.parse(ivBase64);
    const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedBase64);

    // Ensure encrypted data is in the correct format
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: encryptedWordArray,
    });

    // Convert encryption key
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);

    // Decrypt
    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert decrypted data to a UTF-8 string
    let decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

    if (!decryptedText) {
      throw new Error("Decryption resulted in an empty string.");
    }
    return decryptedText.trim();
  } catch (error) {
    console.error("Decryption failed:", error);
    return "";
  }
}