// src/App.js

// 1. External Libraries
import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer';
import { Auth0Provider } from '@auth0/auth0-react';

// 2. Third-Party Libraries
import { Navbar, Nav } from 'react-bootstrap';
import { 
  BsPerson, 
  BsBook, 
  BsCodeSlash,  
  BsLayers, 
  BsBoxArrowInRight, 
  BsQuestionCircle, 
  BsKey, 
  BsBoxArrowLeft,

} from 'react-icons/bs';

// 3. Local Components and Assets
import './App.css';
import BoardManager from './Board_Manager';
import { AuthProvider } from './AuthContext';
import Footer from './Footer';
import RedirectToAuth0 from './RedirectToAuth0';
import ProtectedRoute from './ProtectedRoute'; 
import ImportPage from './ImportPage';

// Lazy-loaded Components
const LoggedIn = lazy(() => import('./LoggedIn'));
const LoggedOut = lazy(() => import('./LoggedOut'));
const NodeCreator = lazy(() => import('./NodeCreator'));

const MarketplaceFrontend = lazy(() => import('./marketplaceFront'));
const ChatPage= lazy(() => import('./chat-files/CombinedChat'));
const MarketplaceDetail = lazy(() => import('./marketplaceDetail'));
const SharedBoardsDisplay = lazy(() => import('./SharedBoardsDisplay'));
const OpenEndPoint = lazy(() => import('./OpenAISpec'));
const FunctionCalling = lazy(() => import('./function-calling'));
const SharedBoardDetail = lazy(() => import('./SharedBoardDetail'));

const NodeAgentChatBoard = lazy(() => import('./NodeAgentChatBoard'));
const AgentBoardList = lazy(() => import('./AgentBoardList'));

const NodeCreatorHeadless = lazy(() => import('./NodeCreatorHeadless'));
const Privacy = lazy(() => import('./Privacy'));

const LogoutPage = lazy(() => import('./LogoutPage'));

const App = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close overlay when a link is clicked
  };

  // NavigationLinks component without dropdown
  const NavigationLinks = () => (
    <>
      {!isAuthenticated ? (
        <>
          <Nav.Link
            href="https://nodecodestudio.com/guide/"
            target="_blank"
            rel="noopener noreferrer"
            className="top-nav-button"
          >
            <BsBook className="nav-icon" /> Guide
          </Nav.Link>

          <Nav.Link as={Link} to="/marketplace" onClick={handleLinkClick} className="top-nav-button">
            <BsCodeSlash className="nav-icon" /> Apis
          </Nav.Link>
          <Nav.Link as={Link} to="/import-workflows" onClick={handleLinkClick} className="top-nav-button">
            <BsLayers className="nav-icon" /> Workflows
          </Nav.Link>
          <Nav.Link
            onClick={() => { loginWithRedirect(); handleLinkClick(); }}
            className="top-nav-button"
          >
            <BsBoxArrowInRight className="nav-icon" /> Login
          </Nav.Link>
        </>
      ) : (
        <>
          <Nav.Link as={Link} to="/" onClick={handleLinkClick} className="top-nav-button">
            <BsLayers className="nav-icon" /> Manage
          </Nav.Link>


          <Nav.Link
            href="https://nodecodestudio.com/guide/"
            target="_blank"
            rel="noopener noreferrer"
            className="top-nav-button"
          >
            <BsBook className="nav-icon" /> Guide
          </Nav.Link>
          <Nav.Link
            href="https://github.com/nikaskeba/Node-Code-Studio/issues"
            target="_blank"
            onClick={handleLinkClick}
            className="top-nav-button"
          >
            <BsQuestionCircle className="nav-icon" /> Help
          </Nav.Link>

        
          <Nav.Link as={Link} to="/logout" className="top-nav-button">
            <BsBoxArrowLeft className="nav-icon" /> Logout
          </Nav.Link>
        </>
      )}
    </>
  );

  const AppContent = () => {
    const location = useLocation();
    const hideFooterRoutes = ['/web-app'];
    const shouldHideFooter = hideFooterRoutes.includes(location.pathname);

    return (
      <div className="app-wrapper">
        {/* Navbar */}
        <div className="navbar-wrapper">
          <Navbar expand="lg" className="app-navbar">
            <div className="navbar-content">
              <Navbar.Brand as={Link} to="https://nodecodestudio.com">
                <img src="https://nodecodestudio.com/wp-content/uploads/2024/12/logowhite.png" alt="Logo" className="header-logo" />
              </Navbar.Brand>
              <Navbar.Toggle onClick={toggleOverlay} className="navbar-toggler">
                <div className="navbar-toggler-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Navbar.Toggle>
              <Navbar.Collapse className="navbar-collapse">
                <Nav className="ml-auto d-flex align-items-center">
                  <NavigationLinks />
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>

{/* Overlay Menu */}
<div className={`overlay ${isOpen ? 'open' : ''}`}>
  <div className="overlay-content">
    <NavigationLinks />
    <a href="#!" className="closebtn" onClick={toggleOverlay}>Close</a>
  </div>
</div>

        {/* Main Content */}
        <div className="content-wrap">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <LoggedIn />
                  </ProtectedRoute>
                }
              />
              <Route path="/redirect" element={<RedirectToAuth0 />} />

              <Route path="/import" element={isAuthenticated ? <ImportPage  /> : <LoggedOut />} />
              <Route path="/endpoint-creator" element={isAuthenticated ? <OpenEndPoint /> : <LoggedOut />} />
              <Route path="/function-calling" element={isAuthenticated ? <FunctionCalling/> : <LoggedOut />} />
    
              <Route path="/node-creator" element={isAuthenticated ? <NodeCreator /> : <LoggedOut />} />
              <Route path="/marketplace" element={<MarketplaceFrontend />} />
      
              <Route path="/marketplace-detail" element={<MarketplaceDetail />} />
           
              <Route path="/agent-board-list" element={isAuthenticated ? <AgentBoardList /> : <LoggedOut />} />
              <Route path="/chat-board" element={isAuthenticated ? <NodeAgentChatBoard /> : <LoggedOut />} />
              <Route path="/agents-list" element={isAuthenticated ? <SharedBoardsDisplay /> : <LoggedOut />} />
              <Route path="/agents-list-detail" element={isAuthenticated ? <SharedBoardDetail /> : <LoggedOut />} />
              <Route path="/web-app" element={<NodeCreatorHeadless />} />
              <Route path="/privacy" element={<Privacy />} />
      
       
              <Route path="/logout" element={<LogoutPage />} />
            </Routes>
          </Suspense>
        </div>

        {/* Footer */}
        {!isAuthenticated && !shouldHideFooter && <Footer />}
      </div>
    );
  };

  return (
    <Router basename="/app">
      <AppContent />
    </Router>
  );
};

// AppWrapper Component
const AppWrapper = () => {
  return (
    <ReactFlowProvider>
      <Auth0Provider
        domain="dev-10kxoqjskx04p600.us.auth0.com" 
        clientId="mLVBGommM72KFnQoUjDvdFTSbDjgvUMr"
        redirectUri={`${window.location.origin}/app/`}
        audience="YOUR_AUTH0_AUDIENCE"
        scope="openid profile email"
      >
        <AuthProvider>
          <App />
        </AuthProvider>
      </Auth0Provider>
    </ReactFlowProvider>
  );
};

export default AppWrapper;