// src/ProtectedRoute.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    // Optionally, render a loading indicator while Auth0 checks authentication
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    return children;
  } else {
    // Redirect to external URL
    window.location.href = 'https://nodecodestudio.com';
    return null; // Render nothing while redirecting
  }
};

export default ProtectedRoute;