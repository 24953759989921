import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Modal, Button, Select, Spin, Row, Col, Tooltip, Input, Pagination, message as AntMessage } from 'antd';
import { EditOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';
import { decryptApiKey, encryptApiKey } from './encryption'; 
import './ChatInterface.css';

function boardNameToFunctionName(boardName) {
  return boardName
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^\w_]/g, ''); // Ensure only letters, digits, and underscores
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const truncateString = (str, num) => {
  if (str.length <= num) return str;
  const lastSpace = str.lastIndexOf(' ', num);
  if (lastSpace === -1) {
    return str.slice(0, num) + '...';
  }
  return str.slice(0, lastSpace) + '...';
};

const getCurrentTime = () => {
  const now = new Date();
  return now.toString();
};

const truncateJson = (data, maxLength = 50) => {
  if (typeof data === 'string') {
    return truncateString(data, maxLength);
  } else if (Array.isArray(data)) {
    return data.map(item => truncateJson(item, maxLength));
  } else if (typeof data === 'object' && data !== null) {
    const truncatedObj = {};
    Object.keys(data).forEach(key => {
      truncatedObj[key] = truncateJson(data[key], maxLength);
    });
    return truncatedObj;
  }
  return data;
};
/**
 * Recursively capitalizes the first letter of each key in a JSON object.
 * @param {Object|Array} obj - The JSON object or array to process.
 * @returns {Object|Array} - The processed JSON object or array with capitalized keys.
 */
const capitalizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => capitalizeKeys(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      acc[capitalizedKey] = capitalizeKeys(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

const customValueRenderer = (defaultRenderer, props) => {
  const { value, type } = props;

  if (type === 'string') {
    return <span className="string-value">{value}</span>;
  }

  return defaultRenderer(props);
};

const formatToolName = (name) => {
  if (!name) return '';
  let formatted = name.replace(/_/g, ' ');
  formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);
  return formatted + '.';
};

const customTheme = {
  base00: "#ffffff", // Background: white
  base01: "#f0f0f0", // Slightly darker background for nested levels
  base02: "#e0e0e0", // Even darker for deeper nesting
  base03: "#b0b0b0", // Less important text
  base04: "#000000", // Keys: black
  base05: "#000000", // Default text: black
  base06: "#000000", // Strings: black
  base07: "#000000", // Numbers and booleans: black
  base08: "#000",     // Accent color (used sparingly)
  base09: "#905",     // Additional accent
  base0A: "000",  // Warnings or important highlights
  base0B: "#0a0",     // Success indicators
  base0C: "#000",     // Information
  base0D: "#00f",     // Links or actions
  base0E: "#000",     // Miscellaneous
  base0F: "#000"      // Errors or critical alerts
};

const formatContent = (content) => {
  if (!content || typeof content !== 'string') {
    return '';
  }

  const sanitizedContent = DOMPurify.sanitize(content, {
    ADD_TAGS: ['pre'],
    ALLOWED_TAGS: ['strong', 'pre', 'br'],
    ALLOWED_ATTR: [],
  });

  return sanitizedContent;
};

const ChatInterface = () => {
  const [messages, setMessages] = useState(() => [
    {
      id: generateUUID(),
      role: 'system',
      content: getCurrentTime(),
      type: 'system',
    },
    {
      id: generateUUID(),
      role: 'assistant',
      content:
        'Select tools to get started or describe your task and the system will attempt to pick the relevant boards.',
      type: 'text',
    },
  ]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [boards, setBoards] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [showToolsPanel, setShowToolsPanel] = useState(false); 
  const [userApiKey, setUserApiKey] = useState('');
  const [GPTKey, setGPTKey] = useState('');
  const [selectedLLM, setSelectedLLM] = useState('');
  const [fireworksKey, setFireworksKey] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [editingModel, setEditingModel] = useState(null);
  const [editedApiKey, setEditedApiKey] = useState('');
  const [USER_ID, setUserId] = useState(localStorage.getItem('userId') || '');
  const isGPTKeyAvailable = Boolean(GPTKey && GPTKey.trim() !== '');
  const isFireworksKeyAvailable = Boolean(fireworksKey && fireworksKey.trim() !== '');
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [isEditingApiKey, setIsEditingApiKey] = useState(null);
  const [tempSelectedModel, setTempSelectedModel] = useState(undefined);
  const [isFirstUserMessage, setIsFirstUserMessage] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8; 
  const embeddingsRef = useRef([]);

  const fetchBoards = async () => {
    try {
      const response = await axios.post(
        'https://nodecodestudio.com/backend/function_calling_backend.php',
        { user_id: USER_ID },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.data.success) {
        setUserApiKey(response.data.user_api_key);
        setBoards(response.data.boards);

        const decryptedGPTKey = decryptApiKey(response.data.api_keys.market_7);
        const decryptedFireworksKey = decryptApiKey(response.data.api_keys.market_11);

        const backendSelectedLLM = response.data.selected_llm;
        const correctedSelectedLLM = backendSelectedLLM === 'fireworksi' ? 'fireworks' : backendSelectedLLM;
        const finalSelectedLLM = correctedSelectedLLM || null;

        setSelectedLLM(finalSelectedLLM);
        setFireworksKey(decryptedFireworksKey);
        setGPTKey(decryptedGPTKey);

        console.log('Selected LLM:', correctedSelectedLLM);
        console.log('Fireworks Key:', decryptedFireworksKey);
        console.log('GPT Key:', decryptedGPTKey);
      } else {
        console.error('Failed to fetch boards:', response.data);
        setMessages((prev) => [
          ...prev,
          {
            id: generateUUID(),
            role: 'assistant',
            content: 'Failed to fetch available tools.',
            type: 'text',
          },
        ]);
      }
    } catch (error) {
      console.error('Error fetching boards:', error);
      setMessages((prev) => [
        ...prev,
        { id: generateUUID(), role: 'assistant', content: 'Error fetching available tools.', type: 'text' },
      ]);
    }
  };

  useEffect(() => {
    fetchBoards();
  }, [USER_ID]);

  useEffect(() => {
    if (showSettingsModal) {
      setTempSelectedModel(selectedLLM);
    }
  }, [showSettingsModal, selectedLLM]);

  const getEmbeddingFromBackend = async (text) => {
    try {
      const response = await axios.post('https://nodecodestudio.com/backend/embed.php', { text }, {
        headers: { 'Content-Type': 'application/json' }
      });
      return response.data.embedding;
    } catch (error) {
      console.error('Error getting embedding from backend:', error);
      return null;
    }
  };

  const generateEmbedding = async (text) => {
    const embedding = await getEmbeddingFromBackend(text);
    return embedding;
  };

  const cosineSimilarity = (vecA, vecB) => {
    let dot = 0;
    let normA = 0;
    let normB = 0;
    for (let i = 0; i < vecA.length; i++) {
      dot += vecA[i] * vecB[i];
      normA += vecA[i]*vecA[i];
      normB += vecB[i]*vecB[i];
    }
    return dot / (Math.sqrt(normA)*Math.sqrt(normB));
  };

  const getRelevantMessages = async (inputText, topK = 5) => {
    const inputEmbedding = await generateEmbedding(inputText);
    if (!inputEmbedding) return [];

    const similarities = embeddingsRef.current.map(({ message, embedding }) => {
      const similarity = cosineSimilarity(inputEmbedding, embedding);
      return { message, similarity };
    });

    similarities.sort((a, b) => b.similarity - a.similarity);
    return similarities.slice(0, topK).map(item => item.message);
  };

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleSaveApiKey = async () => {
    if (!editedApiKey.trim()) {
      AntMessage.error('API Key cannot be empty.');
      return;
    }

    const encryptedApiKey = encryptApiKey(editedApiKey);
    if (!encryptedApiKey) {
      AntMessage.error('Failed to encrypt the API key.');
      return;
    }

    const marketId = editingModel === 'openai' ? 11 : 7;

    try {
      await axios.post(
        'https://nodecodestudio.com/backend/save_api_key.php',
        { market_id: marketId, api_key: encryptedApiKey, user_id: USER_ID },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setEditingModel(null);
      setEditedApiKey('');
      fetchBoards(USER_ID);
      AntMessage.success('API Key successfully updated.');
    } catch (err) {
      console.error('Error updating API key:', err);
      AntMessage.error('Failed to update API key.');
    }
  };
  
  const handleModelChange = (value) => {
    setTempSelectedModel(value);
  };
  
  /**
   * We remove the overlay code here and instead show a system message 
   * if the user tries to send a message while no model is selected.
   */
  const handleSend = async () => {
    if (!input.trim()) return;

    // --- IF NO MODEL IS SELECTED, SHOW SYSTEM MESSAGE IN THE CHAT INSTEAD OF POPUP ---
    if (!selectedLLM) {
      const modelSystemMsg = {
        id: generateUUID(),
        role: 'system',
        content: `No model is selected. Please <a href="#" onclick="document.querySelector('#openSettingsBtn').click(); return false;">open the Settings</a> and select a model.`,
        type: 'system',
      };
      setMessages((prev) => [...prev, modelSystemMsg]);
      setInput('');
      return;
    }
    // ----------------------------------------------------------------------------------

    if (isProcessing) return;

    setLoading(true);
    setIsProcessing(true);

    const userMessage = {
      id: generateUUID(),
      content: input,
      role: 'user',
      type: 'text',
    };

    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInput('');

    const userEmbedding = await generateEmbedding(userMessage.content);
    if (userEmbedding) {
      embeddingsRef.current.push({ message: userMessage, embedding: userEmbedding });
      const MAX_EMBEDDINGS = 100;
      if (embeddingsRef.current.length > MAX_EMBEDDINGS) {
        embeddingsRef.current.shift();
      }
    }

    try {
      if (isFirstUserMessage && selectedTools.length === 0) {
        const systemPrompt = `
determine which boards are relevant based on the user's request.
return only a json in the format {"board_ids": [ ... ]}
based on:
{
    "boards": ${JSON.stringify(boards, null, 2)}
}
Only return the JSON, no additional text.
        `.trim();

        const systemMessage = {
          id: generateUUID(),
          role: 'system',
          content: systemPrompt,
          type: 'system',
        };

        const customMessages = [systemMessage, userMessage];

        await processMessages(customMessages);
        setIsFirstUserMessage(false);
      } else {
        const relevantMessages = await getRelevantMessages(userMessage.content, 5);
        const apiMessages = relevantMessages.map(({ role, content }) => ({ role, content }));
        apiMessages.push({ role: 'user', content: userMessage.content });

        await processMessages(apiMessages);
      }
    } catch (error) {
      console.error('Error fetching response:', error);
      setMessages((prev) => [
        ...prev,
        { id: generateUUID(), role: 'assistant', content: 'Sorry, something went wrong.', type: 'text' },
      ]);
    } finally {
      setLoading(false);
      setIsProcessing(false);
    }
  };

  const getTokenCount = (messages) => {
    let tokenCount = 0;
    for (const message of messages) {
      const { role, content } = message;
      const text = `${role}: ${content}`;
      tokenCount += Math.ceil(text.length / 4) + 4;
    }
    return tokenCount;
  };

  const MAX_MODEL_TOKEN_LIMIT = 8191;
  const MAX_ASSISTANT_REPLY_TOKENS = 1000;

  const processMessages = async (apiMessages) => {
    if (isProcessing) return;
    setIsProcessing(true);
  
    let messagesToSend = [...apiMessages];
    let totalTokenCount = getTokenCount(messagesToSend);
  
    while (
      totalTokenCount + MAX_ASSISTANT_REPLY_TOKENS > MAX_MODEL_TOKEN_LIMIT &&
      messagesToSend.length > 1
    ) {
      messagesToSend.shift();
      totalTokenCount = getTokenCount(messagesToSend);
    }
  
    const truncatedMessagesForLLM = messagesToSend.map((msg) => {
      if (msg.role === 'tool') {
        let parsedContent;
        try {
          parsedContent = JSON.parse(msg.content);
        } catch (error) {
          console.error('Error parsing tool message JSON:', error);
          return { role: msg.role, content: 'Invalid JSON content.' };
        }
        const truncatedContent = truncateJson(parsedContent, 50);
        const truncatedJsonString = JSON.stringify(truncatedContent, null, 2);
        return { role: msg.role, content: truncatedJsonString };
      } else if (msg.role === 'function') {
        const functionName = msg.name || 'unknown_function';
        return { role: msg.role, name: functionName, content: msg.content };
      } else {
        return { role: msg.role, content: msg.content };
      }
    });

    if (selectedLLM === 'fireworks') {
      const payload = {
        model: 'accounts/fireworks/models/firefunction-v2',
        max_tokens: MAX_ASSISTANT_REPLY_TOKENS,
        top_p: 1,
        top_k: 40,
        presence_penalty: 0,
        frequency_penalty: 0,
        temperature: 0.6,
        messages: truncatedMessagesForLLM,
        tools: generateToolsPayloadForFireworks(),
      };

      try {
        const response = await axios.post(
          'https://api.fireworks.ai/inference/v1/chat/completions',
          payload,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${fireworksKey}`,
            },
          }
        );

        await handleAPIResponseFireworks(response.data, apiMessages);
      } catch (error) {
        console.error('Error processing messages with Fireworks:', error);
        setMessages((prev) => [
          ...prev,
          {
            id: generateUUID(),
            role: 'assistant',
            content: 'Error processing your request with Fireworks.',
            type: 'text',
          },
        ]);
      }
    } else if (selectedLLM === 'openai') {
      const openAIFunctions = selectedTools.length > 0 ? generateFunctionsPayloadForOpenAI() : null;
      const openaiPayload = {
        model: 'gpt-4o-mini',
        max_tokens: MAX_ASSISTANT_REPLY_TOKENS,
        temperature: 0.6,
        messages: truncatedMessagesForLLM,
      };

      if (openAIFunctions) {
        openaiPayload.functions = openAIFunctions;
        openAIFunctions.function_call = 'auto';
      }

      try {
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          openaiPayload,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${GPTKey}`,
            },
          }
        );

        await handleAPIResponseOpenAI(response.data, apiMessages);
      } catch (error) {
        console.error('Error processing messages with OpenAI:', error.response?.data || error.message);
        setMessages((prev) => [
          ...prev,
          {
            id: generateUUID(),
            role: 'assistant',
            content: 'Error processing your request with OpenAI.',
            type: 'text',
          },
        ]);
      }
    }

    setIsProcessing(false);
  };

  const handleAPIResponseFireworks = async (data, apiMessages) => {
    const apiMessage = data.choices[0].message;
  
    if (isFirstUserMessage && selectedTools.length === 0 && apiMessage.content) {
      let parsedContent;
      try {
        parsedContent = JSON.parse(apiMessage.content);
      } catch (jsonError) {
        const assistantMessage = {
          id: generateUUID(),
          role: 'assistant',
          content: apiMessage.content,
          type: 'text',
        };
        setMessages((prev) => [...prev, assistantMessage]);
        return;
      }
  
      if (parsedContent && parsedContent.board_ids) {
        const returnedBoardIds = parsedContent.board_ids;
        const matchedBoards = boards.filter((b) => returnedBoardIds.includes(b.board_id));
  
        setSelectedTools(
          matchedBoards.map((b) => ({
            board_id: b.board_id,
            board_name: b.board_name,
          }))
        );
  
        const boardNames = matchedBoards.map((b) => b.board_name);
        const messageText = `I selected ${boardNames.length} boards: ${boardNames.join(', ')}`;
  
        const lastUserMessage = apiMessages.find(
          (m) => m.role === 'user' && m.content === messages[messages.length - 1]?.content
        );
  
        if (!lastUserMessage) {
          setMessages((prev) => [
            ...prev,
            {
              id: generateUUID(),
              role: 'assistant',
              content: messageText,
              type: 'text',
            },
          ]);
          setIsFirstUserMessage(false);
          return;
        }
  
        const newMessages = [
          lastUserMessage,
          {
            id: generateUUID(),
            role: 'assistant',
            content: messageText,
            type: 'text',
          },
        ];
  
        setMessages(newMessages);
        setIsFirstUserMessage(false);
        return;
      }
    }
  
    // If Fireworks returned tool_calls, we append a message with tool_calls and then a tool message
    if (apiMessage.tool_calls && Array.isArray(apiMessage.tool_calls)) {
      // First, append the assistant message with tool_calls
      const assistantWithToolCalls = {
        id: generateUUID(),
        role: apiMessage.role, // usually 'assistant'
        content: "", 
        tool_calls: apiMessage.tool_calls.map(tc => tc)
      };
      setMessages((prev) => [...prev, assistantWithToolCalls]);
  
      // For each tool_call, execute the function/tool
      for (const toolCall of apiMessage.tool_calls) {
        const { functionResponse } = await executeFunctionCall(toolCall.function, 'fireworks');
  
        const toolMessage = {
          id: generateUUID(),
          role: 'tool',
          content: functionResponse, 
          toolName: toolCall.function.name || 'unknown_function'
        };
        setMessages((prev) => [...prev, toolMessage]);
  
        // Embed this tool response for context
        const toolEmbedding = await generateEmbedding(functionResponse);
        if (toolEmbedding) {
          embeddingsRef.current.push({ message: toolMessage, embedding: toolEmbedding });
          if (embeddingsRef.current.length > 100) embeddingsRef.current.shift();
        }
      }
  
      return;
    }
  
    // If no tool_calls, just append the assistant message as normal
    if (apiMessage.content) {
      const assistantMessage = {
        id: generateUUID(),
        role: 'assistant',
        content: apiMessage.content,
        type: 'text',
      };
      setMessages((prev) => [...prev, assistantMessage]);
  
      const assistantEmbedding = await generateEmbedding(apiMessage.content);
      if (assistantEmbedding) {
        embeddingsRef.current.push({ message: assistantMessage, embedding: assistantEmbedding });
        if (embeddingsRef.current.length > 100) {
          embeddingsRef.current.shift();
        }
      }
    } else {
      const emptyMessage = {
        id: generateUUID(),
        role: 'assistant',
        content: 'No response content.',
        type: 'text',
      };
      setMessages((prev) => [...prev, emptyMessage]);
    }
  };

  const handleAPIResponseOpenAI = async (data, apiMessages) => {
    const apiMessage = data.choices[0].message;

    if (isFirstUserMessage && selectedTools.length === 0 && apiMessage.content) {
      let parsedContent;
      try {
        parsedContent = JSON.parse(apiMessage.content);
      } catch (jsonError) {
        const assistantMessage = {
          id: generateUUID(),
          role: 'assistant',
          content: apiMessage.content,
          type: 'text',
        };
        setMessages((prev) => [...prev, assistantMessage]);
        return;
      }

      if (parsedContent && parsedContent.board_ids) {
        const returnedBoardIds = parsedContent.board_ids;
        const matchedBoards = boards.filter((b) => returnedBoardIds.includes(b.board_id));

        setSelectedTools(
          matchedBoards.map((b) => ({
            board_id: b.board_id,
            board_name: b.board_name,
          }))
        );

        const boardNames = matchedBoards.map((b) => b.board_name);
        const messageText = `I selected ${boardNames.length} boards: ${boardNames.join(', ')}`;

        const lastUserMessage = apiMessages.find(
          (m) => m.role === 'user' && m.content === messages[messages.length - 1]?.content
        );

        if (!lastUserMessage) {
          setMessages((prev) => [
            ...prev,
            {
              id: generateUUID(),
              role: 'assistant',
              content: messageText,
              type: 'text',
            },
          ]);

          setIsFirstUserMessage(false);
          return;
        }

        const newMessages = [
          lastUserMessage,
          {
            id: generateUUID(),
            role: 'assistant',
            content: messageText,
            type: 'text',
          },
        ];

        setMessages(newMessages);
        setIsFirstUserMessage(false);
        return;
      }
    }

    if (apiMessage.function_call) {
      const { name, arguments: args } = apiMessage.function_call;
      const funcName = name || 'unknown_function';
      const { functionResponse, isValidJSON } = await executeFunctionCall({ name: funcName, arguments: args }, 'openai');

      if (isValidJSON) {
        const functionMessage = {
          id: generateUUID(),
          role: 'function',
          name: funcName,
          content: functionResponse,
        };
        setMessages((prev) => [...prev, functionMessage]);

        const functionEmbedding = await generateEmbedding(functionResponse);
        if (functionEmbedding) {
          embeddingsRef.current.push({ message: functionMessage, embedding: functionEmbedding });
          if (embeddingsRef.current.length > 100) embeddingsRef.current.shift();
        }
      } else {
        const assistantMessage = {
          id: generateUUID(),
          role: 'assistant',
          content: functionResponse,
          type: 'error',
        };
        setMessages((prev) => [...prev, assistantMessage]);

        const errorEmbedding = await generateEmbedding(functionResponse);
        if (errorEmbedding) {
          embeddingsRef.current.push({ message: assistantMessage, embedding: errorEmbedding });
          if (embeddingsRef.current.length > 100) embeddingsRef.current.shift();
        }
      }

      return;
    }

    if (apiMessage.content) {
      const assistantMessage = {
        id: generateUUID(),
        role: 'assistant',
        content: apiMessage.content,
        type: 'text',
      };
      setMessages((prev) => [...prev, assistantMessage]);

      const assistantEmbedding = await generateEmbedding(apiMessage.content);
      if (assistantEmbedding) {
        embeddingsRef.current.push({ message: assistantMessage, embedding: assistantEmbedding });
        if (embeddingsRef.current.length > 100) {
          embeddingsRef.current.shift();
        }
      }
    } else {
      const emptyMessage = {
        id: generateUUID(),
        role: 'assistant',
        content: 'No response content.',
        type: 'text',
      };
      setMessages((prev) => [...prev, emptyMessage]);
    }
  };

  const generateToolsPayloadForFireworks = () => {
    return selectedTools
      .map((tool) => {
        const board = boards.find((b) => b.board_id === tool.board_id);
        if (!board) return null;
  
        const functionName = boardNameToFunctionName(board.board_name);
  
        return {
          type: 'function',
          function: {
            name: functionName,
            description: board.board_description,
            parameters: {
              type: 'object',
              properties: board.boardParams.reduce((acc, param) => {
                acc[param.responseType] = {
                  description: param.questionText,
                  type: 'string',
                  example: param.example || null,
                };
                return acc;
              }, {}),
              required: board.boardParams.map((param) => param.responseType),
            },
          },
        };
      })
      .filter((tool) => tool !== null);
  };

  const generateFunctionsPayloadForOpenAI = () => {
    return selectedTools
      .map((tool) => {
        const board = boards.find((b) => b.board_id === tool.board_id);
        if (!board) return null;
  
        const properties = {};
        board.boardParams.forEach((param) => {
          properties[param.responseType] = {
            type: 'string',
            description: param.questionText,
          };
        });
  
        const functionName = boardNameToFunctionName(board.board_name);
  
        return {
          name: functionName,
          description: board.board_description,
          parameters: {
            type: 'object',
            properties,
            required: board.boardParams.map((param) => param.responseType),
          },
        };
      })
      .filter((fn) => fn !== null);
  };

  const executeFunctionCall = async (func, modelType) => {
    const { name: functionName, arguments: args } = func;

    let functionResponse = '';
    let isValidJSON = false;

    const board = boards.find((b) => boardNameToFunctionName(b.board_name) === functionName);

    if (board) {
      try {
        const parsedArgs = JSON.parse(args);

        const baseURL = 'https://nodecodestudio.com/backend/endpoint.php';
        const params = new URLSearchParams({
          board_id: board.board_id,
          user_id: USER_ID,
          key: userApiKey,
        });

        board.boardParams.forEach((param) => {
          const value = parsedArgs[param.responseType] || '';
          if (value) {
            params.append(param.responseType, value);
          }
        });

        const url = `${baseURL}?${params.toString()}`;
        console.log(`Executing Tool "${board.board_name}": ${url}`);

        const backendResponse = await axios.get(url);

        if (backendResponse.data.result && backendResponse.data.result.length > 0) {
          const rawJson = backendResponse.data.result[0];
          let parsedJson;

          try {
            parsedJson = JSON.parse(rawJson);
            isValidJSON = true;
          } catch (parseError) {
            console.error('Error parsing JSON:', parseError);
            functionResponse = `Failed to execute tool "${board.board_name}": Invalid JSON response.`;
            isValidJSON = false;
            return { functionResponse, isValidJSON };
          }

          functionResponse = JSON.stringify(parsedJson, null, 2);
        } else {
          functionResponse = `Tool "${board.board_name}" returned no data.`;
        }
      } catch (error) {
        console.error(`Error executing tool "${functionName}":`, error);
        functionResponse = `Failed to execute tool "${board.board_name}".`;
      }
    } else {
      functionResponse = `Function "${functionName}" is not available.`;
    }

    return { functionResponse, isValidJSON };
  };

  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };
  
  const initiateEditApiKey = (modelType) => {
    setEditingModel(modelType);
    const currentKey = modelType === 'openai' ? GPTKey : fireworksKey;
    setEditedApiKey(currentKey || '');
    setIsEditingApiKey(modelType);
  };
  
  const handleSaveSettings = async () => {
    if (!tempSelectedModel) {
      AntMessage.error('Please select a model.');
      return;
    }
  
    try {
      await axios.post(
        'https://nodecodestudio.com/backend/save_selected_llm.php',
        { user_id: USER_ID, selected_llm: tempSelectedModel },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setSelectedLLM(tempSelectedModel);
      AntMessage.success('Model selection saved successfully.');
      toggleSettingsModal();
    } catch (error) {
      console.error('Error saving model selection:', error);
      AntMessage.error('Failed to save model selection.');
    }
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const sortedBoards = [...boards].sort((a, b) => a.board_name.localeCompare(b.board_name));
  const currentBoards = sortedBoards.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const isMaxSelected = selectedTools.length >= 8;

  const handleToolSelection = (board_id) => {
    if (selectedTools.some(tool => tool.board_id === board_id)) {
      setSelectedTools(prev => prev.filter(tool => tool.board_id !== board_id));
    } else {
      if (selectedTools.length >= 8) {
        AntMessage.warning('You can select up to 8 tools only.');
        return;
      }
      const board = boards.find(b => b.board_id === board_id);
      if (board) {
        setSelectedTools(prev => [...prev, { board_id: board.board_id, board_name: board.board_name }]);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="chat-container">
      {/* ---- Removed overlay for no-model-selected ---- */}

      <div className="header">
        <h2>Workflow Chat (beta)</h2>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button type="primary" onClick={() => setShowToolsPanel(true)}>Tools</Button>
          {/* We'll give this button an ID so we can programmatically click it */}
          <Button id="openSettingsBtn" onClick={toggleSettingsModal}>Settings</Button>
        </div>
      </div>

      <div className={`tools-panel ${showToolsPanel ? 'open' : ''}`}>
        <div className="tools-header">
          <Button icon={<ArrowLeftOutlined />} onClick={() => setShowToolsPanel(false)} />
          <h3 style={{ margin: 0 }}>Select Tools</h3>
        </div>
        <ul className="tools-list" style={{ listStyleType: 'none', padding: '10px' }}>
          {currentBoards.map((board) => (
            <li key={board.board_id} style={{ marginBottom: 10 }}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  checked={selectedTools.some((tool) => tool.board_id === board.board_id)}
                  onChange={() => handleToolSelection(board.board_id)}
                  disabled={
                    !selectedTools.some(tool => tool.board_id === board.board_id) && isMaxSelected
                  }
                  style={{ marginRight: 10 }}
                />
                <div>
                  <div style={{ fontWeight: 'bold' }}>{board.board_name}</div>
                  <div style={{ color: '#555' }}>{board.board_description}</div>
                </div>
              </label>
            </li>
          ))}
        </ul>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={boards.length}
          onChange={handlePageChange}
          style={{ textAlign: 'center', margin: 20 }}
          showSizeChanger={false}
        />
        <div style={{ margin: 20, textAlign: 'right' }}>
          <span>{`Selected ${selectedTools.length} of 8`}</span>
        </div>
      </div>

      <Modal
        title="Settings"
        visible={showSettingsModal}
        onCancel={toggleSettingsModal}
        footer={[
          <Button key="cancel" onClick={toggleSettingsModal}>Cancel</Button>,
          <Button key="save" type="primary" onClick={handleSaveSettings}>Save</Button>,
        ]}
      >
        <div>
          <p>Select Model:</p>
          <Row align="middle" gutter={8}>
            <Col flex="auto">
              <Select
                placeholder="Select a Model"
                value={tempSelectedModel || undefined}
                onChange={handleModelChange}
                style={{ width: '100%' }}
              >
                <Select.Option value="fireworks">Fireworks Firefunction v2</Select.Option>
                <Select.Option value="openai">OpenAI gpt-4o-mini</Select.Option>
              </Select>
            </Col>
            <Col>
              <Tooltip title="Edit API Key">
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => initiateEditApiKey(tempSelectedModel)}
                  disabled={!tempSelectedModel}
                />
              </Tooltip>
            </Col>
          </Row>
        </div>

        {isEditingApiKey && (
          <div style={{ marginTop: '20px' }}>
            <p>
              Edit API Key for {isEditingApiKey === 'openai' ? 'OpenAI ChatGPT 4.0' : 'Fireworks Firefunction v2'}:
            </p>
            <Input
              placeholder="Enter API Key"
              value={editedApiKey}
              onChange={(e) => setEditedApiKey(e.target.value)}
              allowClear
            />
            <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
              <Button type="primary" onClick={handleSaveApiKey}>Save</Button>
              <Button onClick={() => setIsEditingApiKey(null)}>Cancel</Button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        className="function-details-modal"
        title="Result"
        visible={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button key="close" type="primary" onClick={closeModal}>Close</Button>
        ]}
        width={800}
        style={{ maxHeight: '80vh', overflow: 'auto' }}
      >
        {modalContent ? (
          (() => {
            try {
              const parsed = JSON.parse(modalContent);
              return (
                <ReactJson
                  src={parsed}
                  theme={customTheme}
                  name={null}
                  collapsed={false}
                  enableClipboard={true}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  quotesOnKeys={false}
                  valueRenderer={customValueRenderer}
                  style={{
                    maxHeight: '60vh',
                    overflow: 'auto',
                    backgroundColor: customTheme.base00,
                    color: customTheme.base05,
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '14px'
                  }}
                />
              );
            } catch {
              return <p>Invalid JSON data.</p>;
            }
          })()
        ) : (
          <p>No content available.</p>
        )}
      </Modal>

      <div className="messages">
        {messages
          .filter((msg) => msg.role !== 'system' || msg.content !== getCurrentTime()) // keep user/system msgs except the "time" if you wish
          .map((msg) => {
            const messageClass =
              msg.role === 'user'
                ? 'user'
                : msg.role === 'assistant'
                ? msg.type === 'error'
                  ? 'assistant-error'
                  : 'assistant'
                : msg.role === 'function'
                ? 'function'
                : msg.role === 'tool'
                ? 'tool'
                : 'system';

            return (
              <div key={msg.id} className={`message ${messageClass}`}>
                <strong>
                  {msg.role === 'user'
                    ? 'You'
                    : msg.role === 'assistant'
                    ? msg.type === 'error'
                      ? 'Error:'
                      : 'Assistant'
                    : msg.role === 'function'
                    ? formatToolName(msg.name || 'unknown_function')
                    : msg.role === 'tool'
                    ? formatToolName()
                    : 'System'}
                </strong>{' '}
                {msg.role === 'function' || msg.role === 'tool' ? (
                  <>
                    <div style={{ margin: '10px 0', color: '#000' }}>
                      Called workflow: {formatToolName(msg.name || msg.toolName || 'Unknown_tool')}
                    </div>
                    <Button 
                      type="primary" 
                      onClick={() => openModal(msg.content)}
                      style={{ marginBottom: '10px' }}
                    >
                      View Result
                    </Button>
                    <div style={{ marginTop: '5px',  color: '#333' }}>
                      Ask a question about the result or request another tool.
                    </div>
                  </>
                ) : msg.type === 'error' ? (
                  <span style={{ color: 'red' }}>{msg.content}</span>
                ) : (
                  // Dangerously set inner HTML for possible links
                  <span dangerouslySetInnerHTML={{ __html: formatContent(msg.content) }} />
                )}
              </div>
            );
          })
        }
        {loading && (
          <div className="message assistant">
            <Spin size="small" /> Assistant: Typing...
          </div>
        )}
      </div>
      <div className="input-area">
        <Input
          type="text"
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={loading}
        />
        <Button type="primary" onClick={handleSend} disabled={loading}>Send</Button>
      </div>
    </div>
  );
};

export default ChatInterface;